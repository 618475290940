export const info1 = {
  heading: 'WHAT WE DO',
  subHeading: 'Your All In One Investment, Property Management, and Security Provider.',
  card: [
    {
      heading: 'Simple',
      span: 'Investing',
      subHeading:
        'We take the time to understand your portfolio, identify strategies and help you execute effectively. We take into account short-term and long-term requirements, goals and defined risk tolerance(s) creating a greater return.'
    },
    {
      heading: 'Property',
      span: 'Management',
      subHeading:
        'Reduce cost and effectively manage your properties and or tenants all while keeping track of costs, and even marketing your property creating higher returns year over year.'
    },
    {
      heading: 'Reliable',
      span: 'Security',
      subHeading:
        'We are committed to security. Securing your investments is essential and important. Our top-of-the-line security solutions with Cisco and Ubiquiti help you secure your investment(s) and focus on what matters most.'
    }
  ]
};
export const info2 = {
  heading: 'OUR FOCUS',
  subHeading: 'Our Solutions',
  card: [
    {
      heading: 'Growth',
      subHeading:
        'Develop, grow and expand your portfolio through proven safe strategies and techniques.'
    },
    {
      heading: 'Management',
      subHeading:
        'Full asset management including: rent collection, vendor payments, financial reporting and more.'
    },
    {
      heading: 'Security',
      subHeading:
        'Securing your investment(s) is key. 24/7 Property Monitoring and surveillance. Let us assist you in protecting your assets.'
    }
  ]
};
