import React from "react";
import Bitmap from '../../Assets/images/Bitmap.png';
import bit from '../../Assets/images/bit.png';
import path from '../../Assets/images/path.png';
import map from '../../Assets/images/map2.png';
import { Helmet } from 'react-helmet';
import ServiceForm2 from '../../Component/ServiceForm2';

const Acquisition = () => {
  return (
    <React.Fragment>
        <Helmet>
            <title>Acquisition</title>
            <meta name="description" content="Tennessee Real Estate Acquisition Services." />
        </Helmet>
      <section className="banner_inde padding_tb ">
        <div className="container">
          <div className="two_grids">
            <div className="inr_grid">
              <h3 className="gensis-font p-color">SERVICES</h3>
              <h2 className="M-Bold_60 hd-color MR-reg-60-1">Acquisition</h2>
              
              <p className="hd-color">
              We work diligently to ensure your investments perform. Vortexx specializes in real estate Acquisition services, connecting clients with the resources 
              needed to secure and manage property investments. With a strong focus on helping landlords source reliable tenants, we streamline the tenant acquisition process 
              and ensure efficient transactions tailored to maximize occupancy and returns.
              </p>
            </div>
            
          </div>
        </div>
      </section>
      
      <section className="tation " >
        <div className="container">
          <div className="grid_mtwo">
            <div className="inr_grid">
              <h3 className="hd-color MR-reg-60">
                Acquisition
                <br />
                <span className="p-color M-Bold_60">Simplified</span>
              </h3>
              
              <p className=" hd-color">
              With experience working alongside businesses of all sizes, from local startups to established corporations, we specialize in providing tailored solutions based on your specific Acquisition needs.
                
              </p>
              <ul className="main_ul">
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Property Upkeep and Vendor Management: We oversee the Acquisition of maintenance services, ensuring properties are maintained in excellent condition. This includes managing vendor relationships and negotiating contracts to secure the best pricing and service quality.
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Financial and Rent Administration: Vortexx management streamlines the Acquisition and oversight of rent collection processes, ensuring accurate financial records. We also manage legal actions when needed to ensure timely payments and compliance.{' '}
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Property Marketing and Tenant Sourcing: Our team handles the Acquisition of marketing services and tenant screening, ensuring optimal tenant selection. We also manage lease agreements, move-in/out logistics, and administrative duties.
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Regulatory Compliance and Risk Management: Vortexx ensures your property meets all local, state, and federal regulations by procuring the necessary services and resources to maintain compliance, minimizing risk.
                </li>
                <li className=" hd-color">
                  {' '}
                  <img src={path} />
                  Dispute Resolution and Mediation: We manage conflict resolution by procuring mediation services and negotiating on your behalf to resolve disputes quickly and effectively, ensuring smooth operations.
                </li>
              </ul>
            </div>
            <div className="inr_grid wid">
              <img src={map} />
            </div>
          </div>
        </div>
      </section>
      <ServiceForm2 />
    </React.Fragment>
    
  );
};

export default Acquisition;
