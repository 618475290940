import React from "react";
import { useNavigate } from 'react-router-dom';
import facebook from '../../Assets/images/fb.png';
import twitter from '../../Assets/images/x.png';
import instagram from '../../Assets/images/li.png';
import address from '../../Assets/images/add.png';
import arrow from '../../Assets/images/org-arrow.png';
import phone from '../../Assets/images/Phone.png';
import email from '../../Assets/images/Mail.png';

function Footer() {
  const navigate = useNavigate();

  return (
    <footer className="footer p-3 p-md-5 bg-black">
      <div className="container">
        <div className="d-flex flex-column flex-md-row justify-content-between mt-3 mt-md-0">
          {/* Logo & Social Media Links */}
          <div className="d-flex flex-column align-items-center gap-2 mb-5 mb-md-0">
            <a href="/">
              <img src="./images/new_logo.png" style={{ width: '200px' }} className="logo-footer" />
            </a>
            <div className="d-flex align-items-center justify-content-center gap-2">
              <a href="https://www.facebook.com/profile.php?id=61565857375030" target="_blank">
                <img src={facebook} className="img-auto" />
              </a>
              <a href="https://x.com/vortexxco2" target="_blank">
                <img src={twitter} className="img-auto" />
              </a>
              <a href="https://www.linkedin.com/company/vortexx-enterprises/" target="_blank">
                <img src={instagram} className="img-auto" />
              </a>
            </div>
          </div>

          {/* Quick Links Section */}
          <div>
            <h3 className="ftr-ql M-Bold_20 hd-wht">QUICK LINKS</h3>
            <div className="inr-ftr-ul d-flex align-items-start">
              <ul className="list-unstyled">
                <li onClick={() => navigate('/')} className="clickable">Home</li>
                <li onClick={() => navigate('/about')} className="clickable">About</li>
                <li onClick={() => navigate('/services')} className="clickable">Services</li>
                <li onClick={() => navigate('/portfolio')} className="clickable">Portfolio</li>
                <li onClick={() => navigate('/privacy')} className="clickable">Privacy Policy</li>
                <li onClick={() => navigate('/terms')} className="clickable">Terms and Conditions</li>
              </ul>
            </div>
          </div>

          {/* Address Section */}
          <div className="mt-3 mt-md-0">
            <h3 className="ftr-ql M-Bold_20 hd-wht mb-3">OUR LOCATION</h3>
            <h4 className="MR-SB-16 hd-wht">
              <img src={address} className="img-auto me-2" />
              Address
            </h4>
            <p className="gray-col mt-2 mb-3">
              2000 Mallory Lane <br />
              Suite 130-145 <br />
              Franklin, TN 37067 <br />
            </p>
            <a href="https://goo.gl/maps/qB3BZB1PzR5Q9JcZ9" target="_blank" className="get-d mt-2 mt-lg-4">
              Get Directions <img src={arrow} className="img-auto me-2" />
            </a>
          </div>

          {/* Contact Info */}
          <div className="mt-5 mt-md-0">
            <h3 className="ftr-ql M-Bold_20 hd-wht mb-3">CONTACT US</h3>
            <h4 className="MR-SB-16 hd-wht">
              <img src={phone} className="img-auto me-2" />
              Phone
            </h4>
            <a className="gray-col mt-2 mb-2 txt-deco" href="tel:+18443981188">
              (844) 398-1188
            </a>
            <h4 className="MR-SB-16 hd-wht mt-4">
              <img src={email} className="img-auto me-2" />
              Email
            </h4>
            <a className="hd-wht gray-col mt-2 mb-2 txt-deco" href="mailto:info@vortexx.co">
              info@vortexx.co
            </a>
          </div>
        </div>
        <hr style={{ background: '#fff', height: '1px', border: 'none' }} />
      </div>
      <div className="footer-dp">
        <div className="container-fluid pt-3">
          <div className="w-100 d-flex flex-column flex-column-reverse flex-lg-row align-items-center">
            <div className="d-flex align-items-center justify-content-center w-100 w-md-50 pt-4">
              <p className="gray-col text-center">
                <span className="Mb_bold_16">VORTEXX ENTERPRISES, LLC </span>© 2025. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

