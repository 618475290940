import React from "react";
import '../../Assets/css/accordion.css';

const faqData = [
  {
    id: "collapseOne",
    heading: "What documents or information do I need to complete the application and screening process?",
    body: `We require the following from the owner: 
           <strong>
           Copy of the Title, Copy of the Deed Restrictions, Copy of the Deed of Sale (if
           the Title is not yet available), or, Copy of the Contract to Sell (if the
           Title and the Deed of Sale are not yet available), Government-issued ID
           </strong>`,
  },
  {
    id: "collapseTwo",
    heading: "When can I expect my property to be tenanted?",
    body: "It depends on several factors, but based on our experience it takes about 1 to 3 months to find a tenant.",
  },
  {
    id: "collapseThree",
    heading: "How does property inspection work?",
    body: "The property manager conducts regular inspections when accepting the rental, at the halfway point of a lease term, and when a tenant moves out.",
  },
  {
    id: "collapseFour",
    heading: "What do you usually look for when screening tenants?",
    body: `<strong>
           Proof of identity, a good credit history, and a stable source of income are our
           basic requirements.
           </strong>`,
  },
];

const Faq = () => {
  return (
      <section className="accordian padding_tb bg_color">
        <div className="container">
          <div className="faq_sec">
            <div className="faq-hd mb-2 mb-lg-5 text-center">
              <h3 className="hd-color MR-reg-60">
                Frequently Asked
                <span className="p-color M-Bold_60"> Questions</span>
              </h3>
            </div>
            <div className="accordion" id="accordionExample">
              {faqData.map((faq, index) => (
                  <div className="accordion-item" key={faq.id}>
                    <h2 className="accordion-header" id={`heading${index + 1}`}>
                      <button
                          className={`accordion-button ${
                              index === 0 ? "" : "collapsed"
                          } Mb_bold_22 hd-color`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#${faq.id}`}
                          aria-expanded={index === 0 ? "true" : "false"}
                          aria-controls={faq.id}
                      >
                        {faq.heading}
                      </button>
                    </h2>
                    <div
                        id={faq.id}
                        className={`accordion-collapse collapse ${
                            index === 0 ? "show" : ""
                        }`}
                        aria-labelledby={`heading${index + 1}`}
                        data-bs-parent="#accordionExample"
                    >
                      <div
                          className="accordion-body"
                          dangerouslySetInnerHTML={{ __html: faq.body }}
                      />
                    </div>
                  </div>
              ))}
            </div>
          </div>
        </div>
      </section>
  );
};

export default Faq;
