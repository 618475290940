import React from "react";
import cont from '../../Assets/images/cont-main.png';
import ContactForm from '../../Component/ContactForm';
import { Helmet } from 'react-helmet';

function Contact() {
  return (
    <React.Fragment>
      <Helmet>
            <title>Contact</title>
            <meta name="description" content="Contact us today and set yourself apart from the competition. Discover the right approach to securing, managing and growing your investments." />
        </Helmet>
      <section className="banner_inde padding_tb c-bg">
        <div className="container">
          <div className="two_grids">
            <div className="inr_grid">
              <h3 className="gensis-font p-color">CONTACT US!</h3>
              <h2 className="M-Bold_80 hd-color">Get In Touch</h2>
              <p className="hd-color">
              At Vortexx Enterprises, we are passionate about helping you achieve your real estate and investment goals. Whether you’re a property owner, landlord, or investor, our team is here to provide personalized guidance and solutions tailored to your unique needs.
              We’d love to hear more about what you’re looking to accomplish and how we can assist. </p> 
              <p>
              Whether it’s sourcing tenants, managing properties, or exploring new investment opportunities, we’re committed to delivering exceptional service and expertise every step of the way.
              Simply fill out the contact form below with your details and a brief message. A member of our team will reach out to you promptly to discuss your goals and answer any questions.
              </p>
              <p>
              At Vortexx Enterprises, your success is our priority. Let’s take the first step together—connect with us today, and let’s make your real estate vision a reality!
              </p>
              
            </div>
            <div className="inr_grid">
              
            </div>
          </div>
        </div>
      </section>
      <ContactForm />
    </React.Fragment>
  );
}

export default Contact;
