import React, { useEffect, useRef, useState } from "react";
import FormContainer from '../FormContainer';
import arrowRight from '../../Assets/images/arrow-right.png';
import { useNavigate } from 'react-router-dom';
import './Appointment.css';

const Appointment = () => {

	const navigate = useNavigate();
	const sectionRef = useRef(null);
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				const [entry] = entries;
				if (entry.isIntersecting) {
					setIsVisible(true);
				}
			},
			{ threshold: 0.2 }
		);

		if (sectionRef.current) {
			observer.observe(sectionRef.current);
		}

		return () => {
			if (sectionRef.current) {
				observer.unobserve(sectionRef.current);
			}
		};
	}, []);

	return (
		<section className="main_appointment p-3 p-md-5">
			<div className={`appoint flex-1w d-flex align-items-center flex-column mb-5 ${isVisible ? 'fade-in-up' : ''}`} ref={sectionRef}>
				<h4 className="gensis-font text-center hd-wht mt-5 mt-md-0">START YOUR JOURNEY TODAY</h4>
				<h4 className="gensis-font text-center hd-wht">
					Investing Is A Journey So Let’s Achieve It Together.
				</h4>

				<button className="btn-apt Mb_bold_16 hd-wht mt-5" onClick={() => window.location.href = 'https://calendly.com/vortexx-info/30min'}>
					BOOK AN APPOINTMENT <img src={arrowRight} alt="" className="img-auto ms-3" />
				</button>
			</div>

			<div className="container d-flex justify-content-stretch appointment-form-wrapper mb-5">
				<div className="d-none d-lg-block appointment-bg">
				</div>
				<div className="appointment-form d-flex flex-column align-items-center justify-content-between bg-white">
					<h3 className="mt-5 mb-2">Send us a message</h3>
					<FormContainer sidebar={false} formName="Appointment" />
				</div>
			</div>
		</section>
	);
};

export default Appointment;
