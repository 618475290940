import React from "react";
import check from '../../Assets/images/cm.png';
import rightArrow from '../../Assets/images/arrow-right.png';
import FormContainer from "../FormContainer";

const ServiceForm = () => {
	return (
		<section className="exerci padding_tb">
			<div className="container">
				<div className="grid_mtwo">
					<div className="inr_grid">
						<h3 className="hd-color MR-reg-60">
							Simplified Services for
							<br />
							<span className="p-color M-Bold_60">All of Your Needs</span>
						</h3>
						<p className="hd-color">
							Let us assist you with your management needs. We'll do the heavy lifting so
							you can focus on developing and growing a strong portfolio.
						</p>
						<div className="m_card mt-2 mt-lg-4">
							<img src={check} alt="" className="m_card-img" />
							<h2 className="m_card-title Mb_bold_22">Property Maintenance</h2>
							<div className="m_card-content"></div>
						</div>
						<div className="m_card mt-2 mt-lg-4">
							<img src={check} alt="" className="m_card-img" />
							<h2 className="m_card-title Mb_bold_22">Contract Management</h2>
							<div className="m_card-content"></div>
						</div>
						<div className="m_card mt-2 mt-lg-4">
							<img src={check} alt="" className="m_card-img" />
							<h2 className="m_card-title Mb_bold_22">Tax and Bookkeeping Services</h2>
							<div className="m_card-content"></div>
						</div>
						<div className="m_card mt-2 mt-lg-4">
							<img src={check} alt="" className="m_card-img" />
							<h2 className="m_card-title Mb_bold_22">Communications and Relations Services</h2>
							<div className="m_card-content"></div>
						</div>
						<div className="m_card mt-2 mt-lg-4">
							<img src={check} alt="" className="m_card-img" />
							<h2 className="m_card-title Mb_bold_22">Property Marketing</h2>
							<div className="m_card-content"></div>
						</div>
						<div className="m_card mt-2 mt-lg-4">
							<img src={check} alt="" className="m_card-img" />
							<h2 className="m_card-title Mb_bold_22">Property Performance Review</h2>
							<div className="m_card-content"></div>
						</div>
						<div className="m_card mt-2 mt-lg-4">
							<img src={check} alt="" className="m_card-img" />
							<h2 className="m_card-title Mb_bold_22">Acquisition</h2>
							<div className="m_card-content"></div>
						</div>
						<div className="m_card mt-2 mt-lg-4">
							<img src={check} alt="" className="m_card-img" />
							<h2 className="m_card-title Mb_bold_22">Surveillance Monitoring</h2>
							<div className="m_card-content"></div>
						</div>
					</div>
					<div className="inr_grid">
						<div className="form_inp">
							<h3 className="gensis-font p-color">INQUIRE ABOUT</h3>
							<h2 className="M-Bold_46 hd-color">Our Services</h2>
							<FormContainer sidebar={true} formName="Service" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ServiceForm;
