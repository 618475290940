import React from "react";
import Slider from "react-slick";
import aws from '../../Assets/images/aws.png';
import AHS from '../../Assets/images/AHS.png';
import premi from '../../Assets/images/premi.png';
import ash from '../../Assets/images/ash.png';
import chur from '../../Assets/images/chur.png';
import cisco from '../../Assets/images/cisco.png';
import ubiquiti from '../../Assets/images/ubiquiti.png';
import cinch from '../../Assets/images/cinch.png';
import rog from '../../Assets/images/rog.png';
import './PartnerBanner.css';

const PartnerBanner = () => {
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
		arrows: false,
		responsive: [
			{
				breakpoint: 1024, // For tablets and smaller laptops
				settings: {
					slidesToShow: 4,
				},
			},
			{
				breakpoint: 768, // For mobile devices in landscape
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 480, // For smaller mobile devices
				settings: {
					slidesToShow: 2,
				},
			},
		],
	};

	const partnerLogos = [aws, AHS, premi, cinch, ash, chur, cisco, ubiquiti, rog];

	return (
		<div className="container mt-5 partner-banner">
			<Slider {...settings}>
				{partnerLogos.map((logo, index) => (
					<div key={index} className="partner-slide">
						<img src={logo} className="img-fluid" alt={`Partner ${index + 1}`} />
					</div>
				))}
			</Slider>
		</div>
	);
};

export default PartnerBanner;
