import React from "react";
import arrowRight from '../../Assets/images/arrow-right.png';
import Button from '../Button';
import { ToastContainer, toast } from 'react-toastify';

const FormContainer = ({ sidebar, formName }) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        toast.success("Thank you for your submission! We'll be in touch soon.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        form.reset();
      })
      .catch(() => {
        toast.error("Something went wrong. Please try again later.");
      });
  };

  return (
    <div className="w-100 h-100 d-flex">
      <div className={"form_inp " + (sidebar ? "form_sidebar" : "")}>
        <ToastContainer />
        <form name={formName} method="POST" data-netlify="true" netlify-honeypot="bot-field">
          <input type="hidden" name="form-name" value={formName} />
          <div className="row">
            <div className={`${sidebar ? 'col' : 'col-xs-12 col-md-6'}`}>
              <input
                type="text"
                placeholder="First Name"
                maxLength={100}
                className="form-control"
                name="first-name"
                required
              />
            </div>
            <div className={`${sidebar ? 'col' : 'col-xs-12 col-md-6'}`}>
              <input
                type="text"
                placeholder="Last Name"
                defaultValue=""
                data-msg-required="Please enter your last name."
                maxLength={100}
                className="form-control"
                name="last-name"
                required
              />
            </div>
          </div>
          <div className="row">
            <div className={`${sidebar ? 'col' : 'col-xs-12 col-md-6'}`}>
              <input
                type="email"
                defaultValue=""
                placeholder="Email Address"
                maxLength={100}
                className="form-control"
                name="email"
                required
              />
            </div>
            <div className={`${sidebar ? 'col' : 'col-xs-12 col-md-6'}`}>
              <input
                type="text"
                placeholder="Phone Number"
                maxLength={100}
                className="form-control"
                name="phone"
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <textarea
                maxLength={5000}
                placeholder="Comments or Message"
                rows={5}
                className="form-control mb-2"
                name="message"
                required
              />
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <Button
              className="w-100 d-flex justify-content-center font-weight-bold"
              rightImage={<img style={{ width: '15px' }} src={arrowRight} alt='arrow right' />}
              title="Submit Now"
              padding="0.8rem 2rem"
              contained
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormContainer;
