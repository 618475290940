import React from 'react';
import arrowRight from '../../Assets/images/arrow-right.png';
import vort from '../../Assets/images/v2.png';
import banner from '../../Assets/images/h-l-2.png';
import { Button, PartnerBanner, PartnerBannerCarousel, Appointment, TestimonialSlider, InfoSection } from '../../Component';
import { useLocation, useNavigate } from 'react-router-dom';
import { info1, info2 } from '../../mocks/information-data';
import { Helmet } from 'react-helmet';
import '../../Assets/css/pages/home.css';

function Home() {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Helmet>
            <title>Home</title>
            <meta name="description" content="Discover the right approach to securing, managing and growing your investments." />
            <meta name="description" content="Find the right approach to securing, managing and growing their investments."/>
            <meta name="keywords" content="real estate, tennessee property management, investments, Vortexx Enterprises, housing market, housing security, aquisition real estate"/>
            <meta name="author" content="Vortexx Enterprises"></meta>
        </Helmet>
      <section className="banner_index padding_tb">
        <div className="container">
          <div className="two_grid">
            <div className="inr_grid">
              <h3 className="gensis-font  p-color">NASHVILLE, TN</h3>
              <h2 className="M-Bold_80 hd-color">Management <br/>Investments <br/> Security</h2>
              <p className="hd-color">
                Your investment should meet no bounds. We help investors find the right approach
                everytime to securing, managing and growing their investments. Specializing in Property Management, Acquisition and Security solutions, Vortexx offers industry leading processes and resources you can rely on. 
                Start your journey with us today and let us help you accelerate your evolving portfolio.
              </p>
              <a>
              <Button
                rightImage={<img style={{ width: '15px' }} src={arrowRight} />}
                title="BOOK AN APPOINTMENT"
                padding="1rem 2rem"
                contained
                onClick={() => window.open('https://calendly.com/vortexx-info/30min', '_blank')}
              />
              </a>
            </div>
            <div className="inr_grid d-g-m">
              <img src={vort} />
            </div>
          </div>
        </div>
      </section>
      <InfoSection {...info1} />
      <section className="exerci padding_tb">
        <div className="container">
          <div className="grid_rtwo">
            <div className="inr_grid wid">
              <img src={banner} />
            </div>
            <div className="inr_grid">
              <h3 className="gensis-font p-color">ABOUT VORTEXX ENTERPRISES</h3>
              <h3 className="hd-color MR-reg-60">
                {' '}
                We Make Strong Financial <span className="p-color M-Bold_60">Success</span> Possible
              </h3>
              <p className="MR-SB-18 hd-color pt-2 pb-2">
                Vortexx is one of the most active investment management groups in Middle Tennessee with a focus on driving your Investments to new heights. 
              </p>
              <p className=" hd-color">
                Investing in your future has never been easier with Vortexx. With a team of experienced and knowledgeable professionals, we are dedicated to helping you achieve your financial goals. 
                Whether you're looking to diversify your investment portfolio, establish management for your assets or simply secure them, we have a solution that fits your unique needs and preferences. 
                <br></br>
                <br></br>
                With a wide range of investment options, cutting-edge technologies, and a commitment to exceptional customer service, you can trust Vortexx to guide you every step of the way. 
                Join us today and experience the peace of mind that comes with working with Vortexx, let us guide you to greater, stronger financial success.
              </p>
              <a target="_blank">
              <Button
                rightImage={<img style={{ width: '15px' }} src={arrowRight} />}
                title="Contact Us To Find Out More"
                padding="0.8rem 2rem"
                contained
                onClick={() => window.open('https://calendly.com/vortexx-info/30min', '_blank')}
              />
              </a>
            </div>
          </div>
        </div>
      </section>
      <TestimonialSlider />
      <InfoSection {...info2} />
      <PartnerBanner />
      
      <Appointment />
    </React.Fragment>
  );
}

export default Home;
