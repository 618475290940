import React from "react";
import map from '../../Assets/images/add-white.png';
import arrow from '../../Assets/images/org-arrow.png';
import phone from '../../Assets/images/Phone-white.png';
import email from '../../Assets/images/Mail-white.png';
import rightArrow from '../../Assets/images/arrow-right.png';
import FormContainer from "../FormContainer";

const ContactForm = () => {
	return (
		<section className="banner_inde padding_tb">
			<div className="container">
				<div className="two_grido">
					<div className="inr_grid">
						{/*end new cards*/}
						<div className="cont-inr-cont cont-add">
							<div className="cardo">
								<div className="inr-ct-img">
									<img src={map} className="img-auto" />
								</div>
								<h4 className="MR-SB-28"> Address</h4>
								<div className="card-contento mb-3">
									<p className=" mt-2 mb-3">
										2000 Mallory Ln <br />
										Suite 130-145 <br />
										Franklin, TN 37067 <br />
									</p>
									<a className="get-d  mt-2 mt-lg-4" href="#">
										Get Directions <img src={arrow} className="img-auto me-2" />
									</a>
								</div>
							</div>
						</div>
						{/*end new cards 2*/}
						<div className="cont-inr-cont cont-email">
							<div className="cardo">
								<div className="inr-ct-img">
									<img src={phone} className="img-auto" />
								</div>
								<h4 className="MR-SB-28 mt-3">Phone</h4>
								<div className="card-contento">
									<a className="MM_para_16 hd-color mt-2 mb-2 txt-deco" href="tel:(844) 398-1188">
										(844) 398-1188
									</a>
								</div>
							</div>
						</div>
						{/*end new cards*/}
						<div className="cont-inr-cont cont-email">
							<div className="cardo">
								<div className="inr-ct-img">
									<img src={email} className="img-auto" />
								</div>
								<h4 className="MR-SB-28 mt-3">Email</h4>
								<div className="card-contento">
									<a
										className="MM_para_16 hd-color mt-2 mb-2 txt-deco"
										href="mailto:info@vortexx.co">
										info@vortexx.co
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="inr_grid col-lg-11">
						<div className="form_inp w-100">
							<div className="w-100">
								<div className="overflow-hidden mb-4 pb-3">
									<h2
										className="M-Bold_46 hd-color"
										data-appear-animation="maskUp"
										data-appear-animation-delay={400}>
										All inquires are responded to within same day!{' '}
									</h2>
								</div>
								<FormContainer sidebar={true} formName='Contact' />
							</div>
							<div className="col-lg-6"></div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ContactForm;
