import React from "react";
import check from '../../Assets/images/cm2.png';
import rightArrow from '../../Assets/images/arrow-right.png';
import FormContainer from "../FormContainer";

const ServiceForm2 = () => {
	return (
		<section className="exerci padding_tb">
			<div className="container">
				<div className="grid_mtwo">
					<div className="inr_grid">
						<h3 className="hd-color MR-reg-60">
							Efficient Operations =
							<br />
							<span className="p-color M-Bold_60">Performance</span>
						</h3>
						<p className="hd-color">
							Let us handle your Acquisition needs, ensuring smooth operations and cost-effective solutions, so you can focus on expanding and optimizing your real estate portfolio.
						</p>
						<div className="m_card mt-2 mt-lg-4">
							<img src={check} alt="" className="m_card-img" />
							<h2 className="m_card-title Mb_bold_22">Vendor Sourcing</h2>
							<div className="m_card-content"></div>
						</div>
						<div className="m_card mt-2 mt-lg-4">
							<img src={check} alt="" className="m_card-img" />
							<h2 className="m_card-title Mb_bold_22">Contract Negotiation and Management</h2>
							<div className="m_card-content"></div>
						</div>
						<div className="m_card mt-2 mt-lg-4">
							<img src={check} alt="" className="m_card-img" />
							<h2 className="m_card-title Mb_bold_22">Lease Management and Tenant Acquisition</h2>
							<div className="m_card-content"></div>
						</div>
						<div className="m_card mt-2 mt-lg-4">
							<img src={check} alt="" className="m_card-img" />
							<h2 className="m_card-title Mb_bold_22">Technology Solutions</h2>
							<div className="m_card-content"></div>
						</div>
						<div className="m_card mt-2 mt-lg-4">
							<img src={check} alt="" className="m_card-img" />
							<h2 className="m_card-title Mb_bold_22">Property Marketing</h2>
							<div className="m_card-content"></div>
						</div>
					</div>
					<div className="inr_grid">
						<div className="form_inp">
							<h3 className="gensis-font p-color">INQUIRE ABOUT</h3>
							<h2 className="M-Bold_46 hd-color">Our Services</h2>
							<FormContainer sidebar={true} formName="Service" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ServiceForm2;