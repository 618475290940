import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import Header from './Component/Header';
import Footer from './Component/Footer';
import Router from './routes/routes';
import ScrollToTop from './Component/ScrollToTop';
import './App.css';

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<ScrollToTop />
				<Header />
				<Router />
				<Footer />
			</BrowserRouter>
		</div>
	);
}

export default App;
